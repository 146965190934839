<template>
   <div class="box box-primary">
            <div class="box-header with-border">
                <i class="fa icon-c icon-qoute-admin"></i>
                <h3 class="box-title text-right">	New Quote Configuaration</h3>
            </div>
            <div class="box-body">
                  <section class="search-parameters">
                    <div class="row">
                        <div class="mtop-15">
                        <div class="nested-panel-heading">
                            <h3 class="nested-title">Quote Request Details</h3>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">"To Quote" Topic <span class="data-v-tooltip" data-v-tooltip="Select the topic that will receive the quote requests. iService will present the quote form when answering questions in this topic. After you select your topic, verify that the interaction properties used to capture quote inputs are correct." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                    <i class="fa fa-question-circle text-info help-icon"></i>
                                    </span>
                                </label>
                                <div class="col-md-12 has-error">
                                   <Multiselect
                                    v-model="value"
                                    placeholder="-Select Topic-"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: '_iService',
                                        options:  [
                                            { value: 'All Topic', name: 'All Topic',level:'1'},
                                            { value: 'Feedback', name: 'Feedback',level:'2'},
                                            
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="interactionproperty filtergroup">
                               
                                <div class="col-md-6 ng-scope">
                                    <label class="control-label text-left">Interaction properties for topic: Quote Requests</label>
                                    <div class="col-md-12 filter-box">
                                        <ul dnd-list="list">
                                            <li>
                                                <div class="property-table">
                                                <div class="table-item-heading"> Property Name </div>
                                                <div class="table-item-heading"> Property Type </div>
                                                <div class="table-item-heading"> Value Type </div>
                                                </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                </div>
                              
                            </div>
                            </div>
                        <div class="col-md-12" style="display: none;">
                            <div class="interactionproperty filtergroup row">
                            <div class="col-md-6">
                                <label class="control-label text-left">Interaction properties for topic: &nbsp; <span class="data-v-tooltip" data-v-tooltip="The interaction properties shown below are configured for the topic you selected, and will be presented to the agent when they prepare the quote. The properties will be presented in the order shown below. To change the order, drag and drop the property into a new location. Then click the save button." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                    <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                                </label>
                                <div class="col-md-12 filter-box">
                                <ul dnd-list="">
                                    <li>
                                    <div class="property-table">
                                        <div class="table-item-heading"> Property Name </div>
                                        <div class="table-item-heading"> Property Type </div>
                                        <div class="table-item-heading"> Value Type </div>
                                    </div>
                                    </li>
                                </ul>
                                <ul dnd-list="list" id="quotelists"></ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group">
                            <label class="col-md-12 control-label text-left"> Stock responses used for the "To Quote" topic <span class="data-v-tooltip" data-v-tooltip="Select the stock responses that contain the quote templates for this topic." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                            </label>
                            <div class="col-md-12">
                                <div class="simple-label multiple">
                                <div class="float-btn-group">
                                    <button class="margin-5 icon-button button-delete" type="button">
                                    <i class="fa fa-times-circle"></i>
                                    </button>
                                     <Multiselect
                                        v-model="value"
                                        placeholder="-Select or Search Stock Response in the list-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                                { value: 'Archived', name: 'Archived',level:'1'},
                                                { value: 'Bounce', name: 'Bounce',level:'1'},
                                                
                                            ]"
                                        >
                                        <template v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                </div>
                                <div class="float-btn-group">
                                    <button class="margin-5 icon-button button-delete" type="button">
                                    <i class="fa fa-times-circle"></i>
                                    </button>
                                    <Multiselect
                                        v-model="value"
                                        placeholder="-Select or Search Stock Response in the list-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                                { value: 'Archived', name: 'Archived',level:'1'},
                                                { value: 'Bounce', name: 'Bounce',level:'1'},
                                                
                                            ]"
                                        >
                                        <template v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                    <button class="margin-5 icon-button button-add-row" type="button">
                                    <i class="fa fa-plus-circle"></i>
                                    </button>
                                    <!--<p v-if="formFeatures.error.stock && !stock.id" class="text-red"><i class="fa fa-warning"></i> Stock response field is required.</p>-->
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="nested-panel-heading">
                            <h3 class="nested-title">Follow Up Ticket Details</h3>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group">
                            <label class="col-md-12 control-label text-left">"Open Quotes" Topic <span class="data-v-tooltip" data-v-tooltip="When a quote is sent to the customer, iService will create a ticket for following up with the customer. Select the topic that will contain these follow-up tickets for open quotes." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
                                <i class="fa fa-question-circle text-info help-icon"></i>
                                </span>
                            </label>
                            <div class="col-md-12 has-error">
                             <Multiselect
                                     v-model="value"
                                    placeholder="-Select or search for a topic-"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: '_iService',
                                        options:  [
                                            { value: 'All Topic', name: 'All Topic',level:'1'},
                                            { value: 'Feedback', name: 'Feedback',level:'2'},
                                            
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        <button type="button" class="btn btn-primary margin-r-5">Create</button>
                        <button type="button" class="btn btn-link">Cancel</button>
                        </div>
                    </div>
                    </section>
            </div>
    </div>

   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewDepartmentPropertyForm from '../components/NewDepartmentPropertyForm.vue';
import DepartmentDetail from '../components/DepartmentDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Department Settings',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    NewDepartmentPropertyForm,
    Toggle,
    Multiselect,
    DepartmentDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showDepartmentDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showDepartmentDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showDepartmentDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showDepartmentDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
